import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Label,
  Select,
  Title,
  Textarea,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';
import { Table } from '@nimbus-ds/components';

const headers = ['Id', 'Tienda', 'Nombre', 'Email', 'Razón Social', 'Acciones'];

import axiosInstance from '../../axios';

const PageDashboard: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(true);
  const [isVinculate, setIsVinculate] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [showMessageVinculateAccount, setShowMessageVinculateAccount] =
    useState(false);
  const [errorValidateStore, setErrorValidateStore] = useState(false);
  const [error, setError] = useState(false);
  const [form, setFormData] = useState({
    nombre_marca: '',
    descripcion: '',
    nombre: '',
    telefono: '',
    email: '',
  });

  interface Seller {
    store_id: string;
    name: string;
    email: string;
    razon_social: string;
    url: string;
    status: string;
  }

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    checkStatusAccount();
    //getListOfPendingSellers();
    //getListOfSellers();
  }, []);

  const handleGoToDashboard = async () => {
    push('/');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const handleChange = (e: any) => {
    setFormData({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const getDashboard = async () => {
    try {
      const r = await axiosInstance.get('/dashboard');
      console.log(r);
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboard();

  const handleSellerStatusChange = (e: any) => {
    console.log(e.target.value, ' - ', e.target.key, ' - ', e.target.label);
    console.log(e.target.id);
    console.log(e.key);
  };

  const checkStatusAccount = async () => {
    console.log('verificando vinculacion de cuenta...');
    try {
      const response = await axiosInstance.get('/check_status_account');
      console.log(response);
      if (response.data.status == true) {
        console.log('CUENTA YA VINCULADA');
        if (response.data.is_vinculate) {
          setIsVinculate(true);
        }
        if (response.data.is_approved) {
          setIsApproved(true);
        }
        setIsVerifing(false);
      }
    } catch (e) {
      console.error(e);
      setIsVerifing(false);
      setIsVinculate(false);
      setErrorValidateStore(true);
    }
    return;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const validateForm = () => {
    if (
      !form.nombre_marca ||
      !form.nombre ||
      !form.email ||
      !form.telefono ||
      !form.email
    ) {
      return false;
    }
    if (validateEmail(form.email) == true) return true;

    return false;
  };

  const handleForm = async () => {
    setLoading(true);
    const isFormValid = validateForm();
    if (isFormValid) {
      setShowMessageVinculateAccount(true);
      try {
        const response = await axiosInstance.post('/seller_register', {
          nombre_marca: form.nombre_marca,
          nombre: form.nombre,
          descripcion: form.descripcion,
          telefono: form.telefono,
          email: form.email,
        });
        if (response.data.status == true) {
          addToast({
            id: 'login-toast',
            type: 'success',
            text: 'Vinculación de cuenta exitosa!',
            duration: 4000,
          });
          setIsVerifing(false);
          setIsVinculate(true);
          setShowMessageVinculateAccount(false);
        } else {
          setShowMessageVinculateAccount(false);
          addToast({
            id: 'login-toast',
            type: 'danger',
            text: 'Error Vinculando cuenta. Verifique sus datos.',
            duration: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setShowMessageVinculateAccount(false);
      }
    } else {
      addToast({
        id: 'form-incomplete-toast',
        type: 'danger',
        text: 'Verifique sus datos.',
        duration: 4000,
      });
    }
    setLoading(false);
    return;
  };

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  if (errorValidateStore)
    return (
      <div>
        <label>Error al verificar estado de la cuenta.</label>
      </div>
    );

  return (
    <Page maxWidth="1000px" marginTop="18">
      {!isVerifing && !isVinculate ? (
        <>
          <Page.Header alignItems="center" title="Formulario de alta" />
          <Card>
            <Card.Header title="" />
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4" padding="4">
                <FormField label="Nombre de tu marca *">
                  <Input
                    name="nombre_marca"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Descripción">
                  <Textarea
                    id="textarea"
                    name="descripcion"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Nombre completo *">
                  <Input
                    name="nombre"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Teléfono de contacto *">
                  <Input
                    name="telefono"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Correo electrónico *">
                  <Input
                    name="email"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
              </Box>
            </Card.Body>
          </Card>
          <Box display="flex" justifyContent="flex-end" gap="4">
            <Button onClick={handleGoToDashboard}>CANCELAR</Button>
            {loading ? (
              <Button appearance="primary">
                <Spinner size="small" color="neutral-background" />
              </Button>
            ) : (
              <Button onClick={handleForm} type="submit" appearance="primary">
                ENVIAR
              </Button>
            )}
          </Box>
          <Box
            display={showMessageVinculateAccount ? 'flex' : 'none'}
            justifyContent="center"
            gap="10"
            marginTop="2"
            marginBottom="6"
          >
            <Text as="p" fontSize="highlight">
              <p>Vinculando cuenta, por favor aguarde unos instantes...</p>
            </Text>
          </Box>
        </>
      ) : (
        <>
          {!isVerifing && isVinculate && !isApproved ? (
            <>
              <Page.Header alignItems="center" title="Sellers" />
              <Page.Body>
                <Layout columns="1">
                  <Layout.Section>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      padding="4"
                    >
                      <Title textAlign="center" as="h3">
                        Esperando aprobación de cuenta
                      </Title>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Page.Body>
            </>
          ) : (
            <>
              {!isVerifing && isVinculate && isApproved ? (
                <>
                  <Page.Header alignItems="center" title="Sellers" />
                  <Page.Body>
                    <Layout columns="1">
                      <Layout.Section>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="4"
                          padding="4"
                        >
                          <Title textAlign="center" as="h3">
                            TU CUENTA HA SIDO APROBADA
                          </Title>
                          <Button
                            appearance="primary"
                            onClick={handleGoToConfig}
                          >
                            <Icon source={<CogIcon />} color="currentColor" />
                            IR A CONFIGURACIÓN
                          </Button>
                        </Box>
                      </Layout.Section>
                    </Layout>
                  </Page.Body>
                </>
              ) : (
                <>
                  <Page.Header alignItems="center" title="Formulario de alta" />
                  <Box display="flex" gap="4" justifyContent="center">
                    <Button appearance="primary">
                      <Icon source={<CogIcon />} color="currentColor" />
                      ERROR
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

export default PageDashboard;
